/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Button, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1iz1p7d --full --parallax" name={"uvod"} fullscreen={true} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"} style={{"maxWidth":1280}}>
              
              <Title className="title-box title-box--shadow4 fs--62" content={"<span style=\"color: var(--color-custom-1--15); font-style: italic;\">Servis plastových oken a dveří včetně eurooken\n<br>Montáž a servis žaluzií <br>Zaměření, instalace rolet a sítí proti hmyzu</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box fs--36 mt--20" content={"<span style=\"color: var(--color-custom-1); font-style: italic;\">Posouzení stavu oken a dveří, zaměření a celková cenová kalkulace bezplatně.&nbsp;</span><br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--cbtn2 btn-box--filling2 fs--24 swpf--uppercase mt--30" href={"/kontakt"} content={"<span style=\"color: var(--color-custom-1);\">Domluvit schůzku</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1iz1p7d --full --parallax" name={"uvod"} fullscreen={true} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"} style={{"maxWidth":1280}}>
              
              <Title className="title-box title-box--shadow4 fs--102" content={"<span style=\"color: var(--color-custom-1--15); font-style: italic;\">První&nbsp;konzultace zdarma.</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--36 mt--20" content={"<br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--cbtn2 btn-box--filling2 fs--24 swpf--uppercase mt--30" href={"/kontakt"} content={"Domluvit schůzku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1iz1p7d --style3 --full --parallax" name={"kontakt"} fullscreen={true} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"} style={{"maxWidth":1280}}>
              
              <Title className="title-box fs--72" content={"<span style=\"font-style: italic; color: var(--color-custom-1--15);\">\"Pro teplíčko, pohodu a úsporu ve Vašem bytě, domě - s námi máte okna fit!\"</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--cbtn2 btn-box--filling2 fs--20 swpf--uppercase mt--30" href={"/kontakt"} content={"Domluvit schůzku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-wguzkb --parallax pb--80 pt--80" name={"paticka"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/b555c770b91248f9819d1b6285ba9591_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--3 pb--16 pl--25 pr--25 pt--10" style={{"maxWidth":1280}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--30 w--600 swpf--uppercase" content={"<span style=\"color: var(--color-custom-1);\">Fit-Okna</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--30" style={{"maxWidth":1000}} content={"<span style=\"color: var(--color-custom-1); font-weight: bold;\">+420 494 909 023\n<br>+420 703 635 038 <br>&nbsp;+420 702 868 880</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--right w--400 lh--16" innerClassName="pl--0 pr--0" href={"/"} style={{"backgroundColor":"var(--color-custom-1--15)"}} content={"<span style=\"color: var(--color-blend--15);\">Úvod</span>"} target={null}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/sluzby"} style={{"backgroundColor":"var(--color-custom-1--15)"}} content={"<span style=\"color: var(--black);\">Služby</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/reference"} style={{"backgroundColor":"var(--color-custom-1--15)"}} content={"<span style=\"color: var(--black);\">Reference</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 w--400 lh--16" innerClassName="pl--0 pr--0" href={"/kontakt"} style={{"backgroundColor":"var(--color-custom-1--15)"}} content={"<span style=\"color: var(--black);\">Kontakt</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}